<template>
  <div class="navbar">
		<div class="left"><slot name="left"></slot></div>
		<div class="center"><slot name="center"></slot></div>
		<div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "NavBar",
}
</script>

<style scoped>
.navbar {
	display: flex;
	text-align: center;
	height: 88px;
	line-height: 88px;
	box-shadow: 0 1px 1px rgba(100,100,100,.1);

	font-size: 30px;
}
.left,.right {
	width: 120px;
	line-height: 88px;
	position: relative;
}
.center {
	flex: 1;
}
</style>
