<template>
  <div class="page">
    <nav-bar class="nav-bar">
      <div slot="left" class="icon-box"><van-icon class="icon" name="arrow-left" size="20" @click="goBack"/></div>
      <div slot="center">产品详情</div>
    </nav-bar>
    <div class="content">
      <!-- <detail-swiper :productInfo='productInfo' :imgList='productInfo.imgList' class="details-swiper"></detail-swiper> -->
      <detail-swiper-video :productInfo='productInfo' :imgList='productInfo.imgList' class="details-swiper"></detail-swiper-video>
      <div class="product-name">
        <div class="level-text">{{productInfo.awardLevelName?productInfo.awardLevelName:'金奖'}}</div>
        <div class="product-text">{{productInfo.productName}}</div>
        <div class="user-text">{{productInfo.userName}}</div>
        <div class="other-text">{{productInfo.awardYear}}年    {{productInfo.awardTypeName}}</div>
      </div>
      <div class="introduction">
        {{productInfo.introduction}}
      </div>
      <div class="link">
        <p>联系电话：{{productInfo.linkPhone}}</p>
      </div>

    </div>
    <div v-if="productInfo.shopUrl" class="shop-btn" @click="goToShop"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import NavBar from '@/components/common/navbar/NavBar'
import DetailSwiper from '@/components/common/swiper/DetailSwiper'
import DetailSwiperVideo from '@/components/common/swiper/DetailSwiperVideo'

import { Icon } from 'vant'

import {listProductInfo} from '../../network/product'

export default {
  name: "Detail",
  data() {
    return {
      productInfo: {}
    }
  },
  components: {
    NavBar,
    DetailSwiper,
    DetailSwiperVideo,
    [Icon.name]: Icon,
  },
  mounted() {
    if(this.$route.query.id) {
      this.listProductInfo(this.$route.query.id)
    }else{
      this.$router.back()
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goToShop(){
      window.location.href = this.productInfo.shopUrl
    },
    // 网络请求
    listProductInfo(id) {
      listProductInfo(id).then(res=>{
        console.log(res)
        if(res.status===200&&res.data) {
          this.productInfo = res.data

          console.log(this.productInfo.awardLevelName)

          this.productInfo.imgList = this.productInfo.pics.split(',')
          if(this.productInfo.video) {
            this.productInfo.imgList.unshift(this.productInfo.video)
          }
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  .icon-box {
    position: relative;
    width: 100%;
    height: 100%;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.content {
  padding-top: 88px;
  padding-bottom: 98px;

  .product-name {
    height: 430px;
    background: url('../../assets/img/name-bg.png');
    background-size: 100% 100%;

    .level-text {
      text-align: center;
      font-size: 44px;
      color: #313131;
      font-weight: 600;
      padding-top: 30px;
    }
    .product-text {
      text-align: center;
      font-size: 44px;
      color: #ffedce;
      font-weight: 600;
      padding-top: 40px;
    }
    .user-text {
      text-align: center;
      font-size: 36px;
      color: #ffedce;
      padding-top: 50px;
    }
    .other-text {
      text-align: center;
      font-size: 36px;
      color: #ffedce;
      padding-top: 40px;
    }
  }
  .introduction {
    padding: 90px 30px;
    border-bottom: 20px solid #eee;
    font-size: 30px;
    color: #707070;
    line-height: 2;
    text-indent: 2em;
  }
  .link {
    padding: 60px 30px;
    font-size: 30px;
    color: #707070;
    line-height: 2;
  }
}
.shop-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 98px;
  background: url('../../assets/img/shop-btn.png');
  background-size: 100% 100%;
}
</style>
