<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" @change="onChange">
    <van-swipe-item v-for="(item,index) in imgList" :key='index'>
      <!-- <video v-if="productInfo.video && index===0" :src="item" id="myvideo" control class="myvideo video-js vjs-default-skin vjs-big-play-centered" preload="none"></video> -->
      <video id="myvideo"
        v-if="productInfo.video && index===0"
        class="myvideo video-js vjs-default-skin vjs-big-play-centered" 
        autoplay="false"
        preload="auto"
        poster="">
        <source :src="item" >
      </video>
      <van-image v-else fit="contain" position="center" :src="item" @click="openPreview(imgList,index)"/>
    </van-swipe-item>
    <template #indicator>
      <div class="custom-indicator">{{ current + 1 }}/{{imgList.length}}</div>
    </template>
  </van-swipe>
</template>

<script type="text/javascript">
import { Swipe, SwipeItem, ImagePreview } from 'vant'
import { Image as VanImage } from 'vant'

import videojs from 'video.js' 
import 'videojs-contrib-hls'
import "@videojs/http-streaming"

export default {
  name: "DetailSwiper",
  data() {
    return {
      current: 0,
      player: null
    }
  },
  props: {
    imgList: {
      type: Array,
      default(){
        return []
      }
    },
    productInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  components: {
    [Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[VanImage.name]: VanImage,
  },
  mounted() {
    setTimeout(() => {
      if(this.productInfo.video){
        this.player = videojs('myvideo', {
          autoplay: false,
          bigPlayButton: true, 
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: false,
          loop: true,
          muted: true //静音模式 、、 解决首次页面加载播放。
        }, function () {
          // this.play() // 自动播放
        })
      }
    }, 300);
  },
  beforeDestroy() {
    if (this.player != null) {
       this.player.dispose() // dispose()会直接删除Dom元素
    }
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    openPreview(itemReviewImgList,index) {
      let imgList = JSON.parse(JSON.stringify(itemReviewImgList))
      if(this.productInfo.video){
        imgList.shift()
        ImagePreview({
          images: imgList,
          startPosition: index-1
        })
      }else{
        ImagePreview({
          images: imgList,
          startPosition: index
        })
      }
    },
  }
}
</script>

<style scoped>
.my-swipe {
  
}
.my-swipe .van-swipe-item .van-image {
  width: 100%;
  height: 480px;
  vertical-align: middle;
}
.my-swipe .van-swipe-item .van-image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 8px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  color: #fff;
}
.myvideo {
  width: 100%;
  height: 480px;
}
</style>
