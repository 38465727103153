<template>
  <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" @change="onChange">
    <van-swipe-item v-for="(item,index) in imgList" :key='index'>
      <div class="videobox" v-if="productInfo.video && index===0">
        <video
          :src="item"
          ref="video"
          :width="width" :height="height"
          class='myvideo'
          id='video'
          controls="controls"
          controlslist="nodownload nofullscreen noremoteplayback"
          :disablePictureInPicture="true">
        </video>
        <div v-show="bofangshow" class="bofang" @click="play">
          <img src="../../../assets/img/play-btn.png">
        </div>
      </div>
      <van-image v-else fit="contain" position="center" :src="item" @click="openPreview(imgList,index)"/>
    </van-swipe-item>
    <template #indicator>
      <div class="custom-indicator">{{ current + 1 }}/{{imgList.length}}</div>
    </template>
  </van-swipe>
</template>

<script type="text/javascript">
import { Swipe, SwipeItem, ImagePreview } from 'vant'
import { Image as VanImage } from 'vant'

import videojs from 'video.js' 
import 'videojs-contrib-hls'
import "@videojs/http-streaming"

export default {
  name: "DetailSwiper",
  data() {
    return {
      current: 0,
      player: null,
      width: '100%',
      height:'100%',
      autoplay: '',
      bofangshow: true
    }
  },
  props: {
    imgList: {
      type: Array,
      default(){
        return []
      }
    },
    productInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  components: {
    [Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[VanImage.name]: VanImage,
  },
  mounted() {
    setTimeout(() => {
      if(this.productInfo.video){
        this.autoplay = ''
      }else{
        this.autoplay = 3000
      }
    }, 300);
    
    // setTimeout(() => {
    //   if(this.productInfo.video){
    //     this.player = videojs('myvideo', {
    //       autoplay: false,
    //       bigPlayButton: true, 
    //       textTrackDisplay: false,
    //       posterImage: true,
    //       errorDisplay: false,
    //       controlBar: false,
    //       loop: true,
    //       muted: true //静音模式 、、 解决首次页面加载播放。
    //     }, function () {
    //       // this.play() // 自动播放
    //     })
    //   }
    // }, 300);
  },
  beforeDestroy() {
    // if (this.player != null) {
    //    this.player.dispose() // dispose()会直接删除Dom元素
    // }
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    play() {
      var video = document.getElementById('video');
      console.log(video.currentTime,video.paused)
      // 判断视频当前状态是 暂停/播放
      if(video.paused){
        video.play()
        this.bofangshow = false
      }else{
        video.pause()
      }
    },
    openPreview(itemReviewImgList,index) {
      let imgList = JSON.parse(JSON.stringify(itemReviewImgList))
      if(this.productInfo.video){
        imgList.shift()
        ImagePreview({
          images: imgList,
          startPosition: index-1
        })
      }else{
        ImagePreview({
          images: imgList,
          startPosition: index
        })
      }
    },
  }
}
</script>

<style scoped>
.my-swipe {
  
}
.my-swipe .van-swipe-item .van-image {
  width: 100%;
  height: 480px;
  vertical-align: middle;
}
.my-swipe .van-swipe-item .van-image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 8px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  color: #fff;
}
.myvideo {
  width: 100%;
  height: 480px;
  position: relative;
}
.videobox {
  position: relative;
}
.bofang {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 150px;
  height: 150px;
}
.bofang>img {
  width: 100%;
  height: 100%;
}


</style>
